import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/professionalism-for-hlt/pulls",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Select an open pull request from a peer `}<strong parentName="a">{`for the short written exercise 1`}</strong></a>{`.  You will provided peer feedback in the form of a pull request review `}<a parentName="p" {...{
        "href": "https://github.com/uazhlt-ms-program/professionalism-for-hlt?tab=readme-ov-file#peer-review",
        "target": "_self",
        "rel": "nofollow"
      }}>{`following the procedure described in the repository README`}</a>{`.`}</p>
    <p>{`Use the following template to structure your feedback:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "markdown"
    }}><pre parentName="div" {...{
        "className": "language-markdown"
      }}><code parentName="pre" {...{
          "className": "language-markdown"
        }}><span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Is the career goal clearly described?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
Write **Yes** or **Consider revising**. 
If **Consider revising**, 
try to provide specific and constructive feedback. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Are at least 3 model professionals or companies identified?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
Write **Yes** or **Consider revising**. 
You may optionally provide suggestions 
for identifying professionals/companies 
that align with the stated career goal. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Ideas for growth`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Are at least 3 activities identified?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
Write **Yes** or **Consider revising**. 
You may optionally provide suggestions. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Do the activities seem realistic and achievable?`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and add your response here. 
Write **Yes** or **Consider revising**. 
If **Consider revising**, 
try to provide specific and constructive feedback. 
-->`}</span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`##`}</span>{` Additional feedback`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- 
delete this comment and optionally add any additional feedback. 
If you want to make recommendations, 
strive to make them specific and constructive. 
-->`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      